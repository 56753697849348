import { request } from './index'

/**
 * 获取领取的任务列表
 * @param {*} data sendChannel:reg 注册 login 登录
 * @returns
 */
export function gettaskList(data) {
  return request({
    method: 'post',
    url: '/user/taskList',
    data
  })
}

/**
 * 设置回传地址
 */
export function setCompleteTask(data) {
  return request({
    method: 'post',
    url: '/user/completeTask',
    data
  })
}

/**
 * 添加知乎关键字
 */
export function addZhihuKeyWords(data) {
  return request({
    method: 'post',
    url: '/zhihu/addKeywords',
    data
  })
}

/**
 * 知乎关键词列表
 */
export function getZhihuKeyWords(data) {
  return request({
    method: 'post',
    url: '/zhihu/keywordList',
    data
  })
}

/**
 * 编辑知乎关键词
 */

export function editKeywords(data) {
  return request({
    method: 'post',
    url: '/zhihu/editKeywords',
    data
  })
}

/**
 * 知乎关键词信息
 */
export function getKeywordsInfo(data) {
  return request({
    method: 'post',
    url: '/zhihu/keywordsInfo',
    data
  })
}

/**
 * 关键词作品回传记录
 */
 export function getZhihuKeywords(data) {
  return request({
    method: 'post',
    url: '/zhihu/keywordsPromotion',
    data
  })
}

/**
 * 关键词作品回传记录
 */
 export function addZhihuKeywords(data) {
  return request({
    method: 'post',
    url: '/zhihu/addKeywordsPromotion',
    data
  })
}