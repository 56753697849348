import { request } from './index'

/**
 * 获取任务列表
 * @param {*} data sendChannel:reg 注册 login 登录
 * @returns
 */
export function getTaskList(data) {
  return request({
    method: 'post',
    url: '/task/TaskList',
    data
  })
}

/**
 * 获取任务推广平台
 */
export function getPromotionPlatform(data) {
  return request({
    method: 'post',
    url: '/user/PromotionPlatform',
    data
  })
}
/**
 * 提交推广书籍
 */
export function submitTask(data) {
  return request({
    method: 'post',
    url: '/user/receiveTask',
    data
  })
}

/**
 * 获取通知列表
 * operatePosition 运营位置 首页INDEX 个人中心USERINFO
 */
export function getOperateInfo(data) {
  return request({
    method: 'post',
    url: '/task/operateInfo',
    data
  })
}

/**
 * 公众号回传作品记录
 * taskId 任务ID
 */
 export function getTaskPromotion(data) {
  return request({
    method: 'post',
    url: '/user/taskPromotion',
    data
  })
}

/**
 * 公众号回传作品
 */
 export function addTaskPromotion(data) {
  return request({
    method: 'post',
    url: '/user/addTaskPromotion',
    data
  })
}