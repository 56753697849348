<template>
  <div class="wrap">
    <div class="add-btn" @click="toAddBack">
      <Plus />
      回传推广链接
    </div>
    <van-list v-model:loading="loading" :finished="true" :immediate-check="false">
      <div v-for="item in list" :key="item.id" class="block">
        <div style="width: 50%;">
          <span>媒体名称：</span>
          <span>{{item.promotionName}}</span>
        </div>
        <div style="width: 50%;">
          <span>媒体账号：</span>
          <span>{{item.platformCode}}</span>
        </div>
        <div style="width: 50%;" v-if="type==='zh'">
          <span>作品类型：</span>
          <span>{{promotionTypeMap[item.promotionType]}}</span>
        </div>
        <div :style="{width: type==='dr' ? '100%' : '50%'}">
          <span>发布日期：</span>
          <span>{{item.promotionDate}}</span>
        </div>
        <div>
          <span>作品链接：</span>
          <span @click.stop="onCopy(item.promotionUrl)" class="book-copy-text">
            <i class="font_family icon-fuzhipeizhi"></i> {{ item.promotionUrl }}
          </span>
        </div>
        <div class="examine-status">
          <span>{{examineStatusMap[item.examineStatus]}}</span>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import Plus from '@/assets/svg/plus.vue'
import { getTaskPromotion } from '@/api/task'
import { getZhihuKeywords } from '@/api/extension'
import { Toast } from 'vant'

export default {
  created() {
    this.getData()
  },
  components: {
    Plus
  },
  data() {
    return {
      list: [],
      loading: false,
      type: this.$route.query.type,
      promotionTypeMap: {  // 推广作品类型
        10: '视频',
        20: '图文',
        30: '其他'
      },
      examineStatusMap: {  // 审核状态
        10: '待审核',
        20: '通过',
        30: '失败'
      }
    }
  },
  methods: {
    getData() {
      this.loading = true
      const { type, id } = this.$route.query
      if (type === 'dr') {
        getTaskPromotion({taskId: id}).then(res => {
          this.list = res
        }).finally(() => this.loading = false)
      } else {
        getZhihuKeywords({keywordsId: this.$route.query.id}).then(res => {
          this.list = res
        }).finally(() => this.loading = false)
      }
    },
    onCopy(text) {
      this.$copyText(text)
        .then(() => {
          Toast('已成功复制到剪切板')
        })
        .catch(() => {
          Toast('复制失败')
        })
    },
    toAddBack() {
      this.$router.push({
        path: this.type === 'dr' ? '/backInfo' : '/editWord',
        query: this.$route.query || {}
      })
    }
  }
}
</script>

<style lang='less' scoped>
.wrap {
  padding: 30px 20px;
}
.add-btn {
  width: 240px;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  color: #fd846f;
  border-radius: 12px;
  border: 2px solid #fd846f;
  margin: 0 auto 30px;
  svg {
    font-size: 30px;
    margin-right: 5px;
  }
}
.block {
  background: white;
  padding: 30px 20px 24px;
  border-bottom: 1px solid rgb(241, 241, 241);
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  > * {
    display: flex;
    align-items: center;
    font-size: 24px;
    padding: 10px;
    span:first-child {
      color: #333;
    }
    span:last-child {
      color: #999;
    }
  }
}
.book-copy-text {
  color: rgb(253, 132, 111) !important;
}
.examine-status {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
</style>